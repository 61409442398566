@import "../variables.scss";
@import "bulma/sass/utilities/_all.sass";

.hero {
  .button,
  .button.is-link {
    border: 1px solid $grey;
  }

  $alpha: 0.4;

  background-color: white;
  background-image: linear-gradient(
      rgba(255, 255, 255, $alpha),
      rgba(255, 255, 255, $alpha)
    ),
    url("/promo2_bg.nologo.landscape.tinify.png");
}

.button.grade-easy {
  color: $easy-grade-color-fg;
  background-color: $easy-grade-color-bg;
}

.button.grade-medium {
  color: $medium-grade-color-fg;
  background-color: $medium-grade-color-bg;
}

.button.grade-hard {
  color: $hard-grade-color-fg;
  background-color: $hard-grade-color-bg;
}
