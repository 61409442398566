$section-padding: 2rem 1rem;
@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/helpers/_all.sass";
@import "bulma/sass/base/_all.sass";
@import "bulma/sass/layout/section.sass";
@import "bulma/sass/layout/footer.sass";
@import "bulma/sass/layout/hero.sass";
@import "bulma/sass/grid/columns.sass";
@import "bulma/sass/form/shared.sass";
@import "bulma/sass/form/tools.sass";
@import "bulma/sass/elements/_all.sass";
@import "bulma/sass/components/navbar.sass";
@import "bulma/sass/components/modal.sass";
@import "bulma/sass/components/message.sass";
@import "bulma/sass/components/breadcrumb.sass";

.button {
  // this has the effect of disabling accidental zoom which can happen
  // when a button is accidentally double-clicked
  //
  // see https://developer.mozilla.org/en-US/docs/Web/CSS/touch-action#manipulation
  // for reference
  touch-action: manipulation;
}

// allows Link component from React Router to be unclickable
// when disabled
// see https://github.com/ReactTraining/react-router/issues/1082#issuecomment-160348163
a[disabled] {
  pointer-events: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
