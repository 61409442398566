$duration: 1.5s;
@import "bulma/sass/utilities/_all.sass";
@import "../variables.scss";

.button.scoring {
  transition: background-color $duration ease-in-out,
    color $duration ease-in-out, box-shadow $duration ease-in-out;
}

.button.scoring.is-warning {
  box-shadow: 0px 0px 3px 3px $warning;
}

.button.scoring.is-success {
  box-shadow: 0px 0px 5px 5px $success;
}

.button.scoring.is-success.woopwoop {
  animation: throb 0.7s ease-in-out infinite alternate;
}

@keyframes throb {
  from {
    box-shadow: 0px 0px 5px 5px $success;
  }

  to {
    box-shadow: 0px 0px 0px 0px $success;
  }
}

.scoring-breakdown tr.team.team-a {
  color: $team-a-color-fg;
  background-color: $team-a-color-bg;
}

.scoring-breakdown tr.team.team-b {
  color: $team-b-color-fg;
  background-color: $team-b-color-bg;
}

.scoring-breakdown tr.team.team-c {
  color: $team-c-color-fg;
  background-color: $team-c-color-bg;
}

.scoring-breakdown tr.team.team-d {
  color: $team-d-color-fg;
  background-color: $team-d-color-bg;
}
